import React, { useState, useEffect, Suspense } from 'react';
import { Grid, Box } from '@material-ui/core';
import NavBarSocialMedia from '../../components/navBar/NavBarSocialMedia';
import '../../styles/components/shared/macroStepPage.scss';
import workflowService from '../../services/workflow.service';

import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MuiAccordion from '@material-ui/core/Accordion';
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import eventTrackingService from './../../services/eventtracking.service';

const Faq = (props) => {
    const { taskDefinitionKey } = props;
    const { hashKey } = props;

    const { full, faqs } = props;
    const [allFaqs, setAllFaqs] = useState(null);

    const history = useHistory();

    const Accordion = withStyles({
        root: {
            border: 'none',
            boxShadow: 'none',
            '&:not(:last-child)': {
                borderBottom: 0,
            },
            '&:before': {
                display: 'none',
            },
            '&$expanded': {
                margin: 'auto',
            },
        },
        expanded: {},
    })(MuiAccordion);

    useEffect(() => {
        async function getFAQS() {
            try {
                let allFaqs = (await workflowService.getFAQ(null, null)).data;
                setAllFaqs(allFaqs);
            } catch (error) {
                console.error(error);
            }
        }
        if (!faqs) {
            getFAQS();
        }
    }, []);

    const handleChange = (pregunta) => (event, isExpanded) => {
        if (isExpanded) {
            eventTrackingService.trackEvent('faq', pregunta, null, 1);
        }
    };

    return full ? (
        <>
            <Suspense>
                <NavBarSocialMedia hashKey={props.match.params.hashKey} taskDefinitionKey={props.match.params.taskDefinitionKey} keepSameTask={true}/>
                <Box className="assetBg"></Box>
                <Grid container direction="row">
                    <Grid item xs={12} lg={8} className="">
                        <Grid item container direction="row" className="bg " style={{ minHeight: '55vh' }}>
                            <Grid item container direction="column" justifyContent="center" alignItems="center" xs={12}>
                                <Grid item container xs={10} className="">
                                    <Grid item xs={12} className="">
                                        <div className="mt-8"></div>
                                        
                                        <p className="d-h2 color-light-blue mt-2">¡Te ayudamos si tenés dudas!</p>
                                        <Box className="mt-2"></Box>
                                        <p className="d-h1 fw-500 color-primary ">Estamos para vos</p>

                                        <Grid item lg={12} md={8} className="mt-4" style={{ marginBottom: '60px' }}>
                                            <Box className="fs-20 fw-500 color-primary mt-4">Consultas frecuentes</Box>
                                            <Box className="divider mt-2"></Box>
                                            {allFaqs &&
                                                allFaqs.map((faq, i) => (
                                                    <>
                                                        <Accordion id={i} onChange={handleChange(`${faq.question}`)} defaultExpanded>
                                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
                                                                <Typography>{faq.question}</Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                <Typography>{faq.answerData}</Typography>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                        <Box className="divider"></Box>
                                                    </>
                                                ))}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Suspense>
        </>
    ) : (
        <Grid item lg={10} md={8} className="mt-6">
            <Box className="fs-20 fw-500 color-primary mt-6">Consultas frecuentes</Box>
            <Box className="divider mt-2"></Box>
            {faqs &&
                faqs.map((faq, i) => (
                    <>
                        <Accordion id={i} onChange={handleChange(`${faq.question}`)} defaultExpanded>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
                                <Typography>{faq.question}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>{faq.answerData}</Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Box className="divider"></Box>
                    </>
                ))}
        </Grid>
    );
};

export default Faq;
