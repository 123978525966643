import React, { useEffect, useRef, useState } from 'react';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            style={{ width: '145px', border: 'none', color: '#00a1da', fontSize: '26px' }}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator="."
            decimalSeparator=","
            isNumericString
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
};

const CurrencyInput = (props) => {
    const { onChange, onChangeCommitted, sliderValue } = props;
    const [committedValue, setCommittedValue] = useState(props.value);
    const [value, setValue] = useState(props.value);
    const [roundInterval, setRoundInterval] = useState(props.roundInterval);

    const [changeValue, setChangeValue] = useState(null);

    const inputElement = useRef(null);

    useEffect(() => {
        setChangeValue(false);
        setValue(sliderValue);
    }, [sliderValue]);

    useEffect(() => {
        setChangeValue(true);
        setCommittedValue(props.value);
        setValue(props.value);
    }, []);

    useEffect(() => {
        if (committedValue) {
            const constrainedValue = constrainValue(committedValue);
            if (roundInterval) {
                setValue(~~(constrainedValue / roundInterval) * roundInterval);
            } else {
                setValue(constrainedValue);
            }
            if (inputElement) {
                inputElement.current.blur();
            }
        }
    }, [committedValue]);

    const constrainValue = (value) => {
        if (value < props.inputProps.min) {
            return props.inputProps.min;
        }
        if (value > props.inputProps.max) {
            return props.inputProps.max;
        }
        return value;
    };

    const handleValueChange = (event) => {
        if (changeValue) {
            const numberValue = event.target.value;
            if (!Number.isNaN(numberValue)) {
                if (onChange) {
                    onChange(numberValue);
                }
            }
            setValue(event.target.value);
        } else {
            setChangeValue(true);
        }
    };

    const commitCurrentValue = () => {
        const numberValue = value;
        if (!Number.isNaN(numberValue)) {
            const constrainedValue = constrainValue(numberValue);
            setValue(constrainedValue);
            setCommittedValue(constrainedValue);
            onChangeCommitted(constrainedValue);
        } else {
            setValue(committedValue);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' || event.key === 'Tab') {
            if (inputElement) {
                inputElement.current.blur();
            }
        } else if (event.key === 'Escape') {
            setValue(committedValue);
        }
    };

    const handleBlur = (event) => {
        commitCurrentValue();
    };

    return (
        <TextField
            inputRef={inputElement}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            value={value}
            InputProps={{
                inputComponent: NumberFormatCustom,
            }}
            onChange={handleValueChange}
        />
    );
};

export default CurrencyInput;
