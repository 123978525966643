import React, { useState, useEffect } from 'react';
import { Button, Grid, Box, TextField } from '@material-ui/core';
import Loader from '../../../components/loader/Loader';
import loanRequestService from '../../../services/loanRequest.service';
import workflowService from '../../../services/workflow.service';
import verificationPendingImage from '../../../assets/email_verification_pending.svg';
import ReactCodeInput from 'react-code-input';
import exclamationImage from './../../../assets/importantInfo.svg';

const MAX_WAIT_TIME = 180;

export const CheckEmailValidation = (props) => {
    const { hashKey, defaultEmail, onVerificationCodeSent, onReset, onError, isRetrying = false } = props;
    const [seconds, setSeconds] = useState(MAX_WAIT_TIME);
    const [validationCodeSentByEmail, setValidationCodeSentByEmail] = useState(false);
    const [loading, setLoading] = useState(null);
    const [newEmail, setNewEmail] = useState(defaultEmail);
    const [isValid, setIsValid] = useState(false);
    const [state, setState] = useState("display_email");
    const emailRegex = /\S+@\S+\.\S+/;
    const [pin, setPin] = useState(null);
    const [pinCompleted, setPinCompleted] = useState(false);

    useEffect(() => {
        if (seconds <= 0) {
            setState("display_email");
            setValidationCodeSentByEmail(false);
            setSeconds(MAX_WAIT_TIME);
            if (onReset) {
                onReset();
            }
        }
    }, [seconds]);

    useEffect(() => {
        if (validationCodeSentByEmail) {
            const timer = setInterval(() => {
                //setSeconds(seconds - 1);
                setSeconds(prevSeconds => prevSeconds - 1);
            }, 1000);
            return () => clearInterval(timer);
        }
    });

    useEffect(() => {
        if(isRetrying) {
            setValidationCodeSentByEmail(true);
            setState("verification_code_sent");
        } else {
            setState("display_email");
        }
        
        setIsValid(true);
    }, [defaultEmail]);

    useEffect(() => {
        if(pin) {
            setPinCompleted(pin.length === 4);
        }
    },[pin] )

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    const isEmail = (email) => {
        return emailRegex.test(email);
    };

    const handleEditEmailClicked = (event) => {
        event.preventDefault();
        setState("edit_email");
    };

    const handleEmailChanged = (event) => {
        const emailValue = event.target.value.trim();
        const validEmail = isEmail(emailValue);
        setNewEmail(emailValue);
        setIsValid(validEmail);
    };

    const sendEmailVerificationCode = async () => {
        try {
            setLoading(true);
            await loanRequestService.update(hashKey, { email: newEmail });
            await workflowService.sendEmailValidation(hashKey);
            setValidationCodeSentByEmail(true);
            setState("verification_code_sent");
            setValidationCodeSentByEmail(true);
            if (onVerificationCodeSent) {
                onVerificationCodeSent();
            }
        } catch (error) {
            if (onError) {
                onError(error);
            }
        } finally {
            setLoading(false);
        }
    };

    const handleChangePin = (value) => {
        setPin(value);
    };

    const handleNextClicked = (event) => {
        event.preventDefault();
        props.onVerificationPinSet(pin);
    };

    return (
        <>
            <Loader loading={loading} />
            <Grid container direction="column">
                {state === "display_email" && (<>                
                <Box className="mt-2">
                    <p className="fw-400 color-primary mt-1">
                        Te enviaremos un correo a
                    </p>
                    <strong className="fs-14 fw-600">{newEmail} - </strong>
                    <a href="#" className="link fs-14 fw-400 color-link-blue" onClick={handleEditEmailClicked}>
                        EDITAR
                    </a>                               
                </Box>
                <div className="box mt-2">
                    <img src={exclamationImage} width="22px" height="22px"/>
                    <div>
                        <p className="fs-14 fw-700">No te olvides de revisar la casilla de SPAM.</p>
                    </div>
                </div> 
                </>
                )}
                {state === "edit_email" && (
                    <Grid item container direction="row" align="center">
                        <Grid item xs={12} sm={8}>
                            <TextField
                                style={{ marginTop: '10px', width: '100%' }}
                                id="mail"
                                label="Email"
                                name="email"
                                value={newEmail}
                                onChange={handleEmailChanged}
                                error={!isValid}
                                helperText='Ej: ejemplo@gmail.com'
                            />
                        </Grid>                      
                    </Grid>
                )}
                {(state === 'display_email' || state === "edit_email") && (<Grid container justifyContent="center" alignItems="center" className=" mt-2">
                    <Grid item xs={12} sm={8} md={6} lg={4} className="flex center">
                        <Box className="mt-4"></Box>
                        <div className="startButton center mb-20-web mt-20-web">
                            <Button
                                className="width-100-per width-80-res"
                                variant="contained"
                                color="primary"
                                onClick={sendEmailVerificationCode}
                                disabled={!isValid || loading}>
                                Enviar email
                            </Button>
                        </div>
                    </Grid>
                </Grid>)}
                {state === "verification_code_sent" && (<>
                    <Grid container justifyContent="center">
                        <Grid item>
                            <img className="mt-5" src={verificationPendingImage} width="70px" alt="Email pendiente de verificación"></img>
                        </Grid>
                        <Grid item>
                            <p className="d-h1 fw-500 color-primary text-align-center mt-2">Te enviamos un email con un link para verificar tu correo</p>
                        </Grid>
                        <Grid container justifyContent="center">
                            <Grid item xs={11}>
                                <p className="fw-400 color-primary text-align-center mb-2 mt-2">Ingresá el código que te enviamos para continuar con tu solicitud</p>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="center" alignItems="center" direction="column">
                            <Grid item xs={12} className=" ">
                                <ReactCodeInput
                                    name="pin"
                                    fields={4}
                                    style={{ marginTop: '20px' }}
                                    onChange={handleChangePin}
                                    autoComplete="true"
                                    filterChars="qwertyuiopasdfghjklñzxcvbnm, .-QWERTYUIOPASDFGHJKLÑZXCVBNM{}[]+´|@"
                                    inputMode="numeric"
                                />
                            </Grid>
                        </Grid>                       
                        <Grid item xs={12}>
                            <p className="fs-13 fw-400 text-align-center mt-10">
                                Si no lo recibiste podés reenviarlo dentro de <span className="fw-700">{minutes}:{remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}</span>
                            </p>
                        </Grid>
                        <Grid item xs={12} sm={8} md={6} lg={4} className="flex center">
                            <Box className="mt-4"></Box>
                            <div className="startButton center mb-20-web mt-20-web">
                                <Button
                                    className="width-100-per width-80-res"
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNextClicked}
                                    disabled={!pinCompleted}>
                                    Continuar
                                </Button>
                            </div>
                        </Grid>                        
                    </Grid>
                </>)}
            </Grid>
        </>
    );
};
