import React, { useEffect, useState } from 'react';
import Tabs from '../components/login/tabs';
import '../styles/components/login.scss';
import MacroPage from './shared/MacroPage';
import { Grid } from '@material-ui/core';

export const Login = (props) => {
    const useWindowSize = () => {
        const [windowSize, setWindowSize] = useState({
            width: null,
            height: null,
        });

        useEffect(() => {
            function handleResize() {
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
            }
            window.addEventListener('resize', handleResize);
            handleResize();
            return () => window.removeEventListener('resize', handleResize);
        }, []);
        return windowSize;
    };

    const size = useWindowSize();

    return (
        <>
            <MacroPage hideWhatsAppComponent>
                <Grid container>
                    <Grid item xs={12} className="mt-2">
                        <p className="fs-16 fw-400 color-link-blue">Sacalo ahora y empezá a disfrutar</p>
                        <h3 className=" fw-500 fs-25 color-primary mt-1">Tu Préstamo Personal 100% online</h3>
                        <Tabs {...props} className=""></Tabs>
                    </Grid>
                </Grid>
                <Grid item xs={10} md={12} className="footer mt-2">
                    BANCO MACRO S.A. CUIT 30-50001008-4-AV. EDUARDO MADERO N°1182, CABA
                </Grid>
            </MacroPage>
        </>
    );
};
