import axiosInstance from './axios.config';
import eventTrackingService from './eventtracking.service';

const RESTAPI_VERSION = '/v1';

const deviceCommunicationService = {
    getDocumentsUploadMiniUrl: (hashKey, requestDto) => {
        return axiosInstance.post(`${RESTAPI_VERSION}/workflow/loanRequest/${hashKey}/documentUpload/minihash`, requestDto);
    },

    getHashkeyFromMinihash: (minHash) => {
        return axiosInstance.get(`${RESTAPI_VERSION}/workflow/minhash/${minHash}`).then((response) => {
            const isClient = response.data.cobisCustomerId !== null ? '1' : '0';
            eventTrackingService.setIsClient(isClient);
            eventTrackingService.setDniId(response.data.customerId);
            eventTrackingService.setTestingGroup(response.data.testingGroup);
            eventTrackingService.setCustomerSex(response.data.sex);
            return response;
        });
    },

    getMinifiedUrl: (minHash) => {
        return axiosInstance.get(`${RESTAPI_VERSION}/workflow/minified-urls/${minHash}`);
    },

    sendPicturesUploadFromMobileTextMessage: (hashKey, requestDto) => {
        return axiosInstance.post(`${RESTAPI_VERSION}/workflow/loanRequest/${hashKey}/documents-upload-links`, requestDto);
    },
};

export default deviceCommunicationService;
