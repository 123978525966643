import React, { useState, useEffect } from 'react';
import '../styles/components/offerSelection.scss';
import '../styles/components/offerSimulation.scss';
import MacroStepPage from './shared/MacroStepPage';
import { OfferSelector } from '../components/offerSelection/OfferSelector';
import { OfferTerms } from '../components/offerSelection/OfferTerms';
import MainButton from '../components/MainButton';
import { Grid, Box, FormControlLabel, Switch } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import offerService from '../services/offer.service';
import Loader from '../components/loader/Loader';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import eventTrackingService from '../services/eventtracking.service';
import Toaster from '../components/alerts/Toaster';
import workflowService from '../services/workflow.service';
import moneyIcon from '../assets/money-icon.svg';
import close from '../assets/close.svg';

const useStyles = makeStyles(() => ({
    customLabelPlacementStart: {
        width: '100%',
        justifyContent: 'flex-end',
    },
}));

const CustomSwitch = withStyles({
    switchBase: {
        color: '#c7c7c7',
    },
    checked: {},
    track: {},
})(Switch);

const MAXIMUM_AMOUNT = 8000000;
const MINIMUM_AMOUNT = 10000;
const STEP = 1000;

export const OfferSimulationPage = (props) => {
    const classes = useStyles();
    const [checkIsCustomer, setCheckIsCustomer] = useState(false);
    const [offer, setOffer] = useState(null);
    const [installments, setInstallments] = useState(null);
    const history = useHistory();
    const [loading, setLoading] = useState(null);
    const [sliderAmount, setSliderAmount] = useState(MAXIMUM_AMOUNT);
    const [selectedInstallment, setSelectedInstallment] = useState(null);

    const [message, setMessage] = useState(null);
    const [open, setOpen] = useState(false);
    const analyticsStep = 1;
    const [popupVisible, setPopupVisible] = useState(true);

    const handleOfferSelected = (amount, offer) => {
        setOffer(offer);
        setSliderAmount(amount);
    };

    const ShowToaster = (props) => {
        return <Toaster elevation={6} variant="filled" {...props} />;
    };

    const closePopup = () => {
        setPopupVisible(false);
    };

    const handleSliderSelectorValue = (amount, checkIsCustomerSelected) => {
        async function getInstallmentsPlan(amount) {
            try {
                setLoading(true);
                let newInstallments = (await offerService.getSimulationInstallments(amount, checkIsCustomerSelected)).data;
                setInstallments(newInstallments);
                setSelectedInstallment(newInstallments[0]);
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        }

        if (amount) {
            getInstallmentsPlan(amount);
        }
    };

    const handleNextClicked = () => {
        async function canStart() {
            try {
                setLoading(true);
                eventTrackingService.trackEvent('inicio solicitud', 'inicio de solicitud', sliderAmount, analyticsStep);
                let workingDay = (await workflowService.canStartRequest()).data;
                setLoading(false);
                history.push({ pathname: `/customerIdentification`, search: window.location.search });
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        }
        canStart();
    };

    const handleAcceptChange = (event) => {
        setCheckIsCustomer(event.target.checked);
        eventTrackingService.trackEvent('simulation-changed', 'cobra sueldo en macro', event.target.checked ? 'Si' : 'No', analyticsStep);
        handleSliderSelectorValue(sliderAmount, event.target.checked);
    };

    const handleToasterClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (props.location.search) {
            var qs = require('qs');
            setMessage(qs.parse(props.location.search, { ignoreQueryPrefix: true }).errorMessage);
            if (message) {
                setOpen(true);
            }
        }
    }, [props.location.search]);

    return (
        <>
            <MacroStepPage
                headerTitle="Simulá la cuota de tu Préstamo"
                hideStepper={true}
                hideQuestions={false}
                hideWhatsAppComponent
                imgFaqClass={'offerSimulationAsset'}>
                <Grid item xs={12} md={11}>
                    <div className="CustomSizeOffer mt-2">
                        <Loader loading={loading} />

                        <Grid item container xs={12} direction="column" className="mt-2 horizontalStartmobile">
                            <Grid item container xs={12} direction="row" className="verticalCenterWeb">
                                <Box className="rounderedStepper">
                                    <p className="p">1</p>
                                </Box>
                                <Grid item xs={10}>
                                    <p id="input-slider" className="fs-16 fw-400 color-primary ml-1">
                                        ¿Cobrás tu sueldo o jubilación en Banco Macro?
                                    </p>
                                    {props.extraInfo && <p className="fs-14 fw-300 color-primary ml-1 mt-1">{props.extraInfo}</p>}
                                </Grid>
                            </Grid>

                            <Grid item xs={12} className="ml-2">
                                <FormControlLabel
                                    className="fs-16 fw-400 color-primary mt-1"
                                    style={{ marginLeft: '30px' }}
                                    control={
                                        <Grid component="label" container alignItems="center" className="checkboxLabelContainer">
                                            <Grid item className={checkIsCustomer ? 'thumbUnchecked' : 'thumbChecked'}>
                                                No
                                            </Grid>
                                            <Grid item>
                                                <CustomSwitch checked={checkIsCustomer} onChange={handleAcceptChange} name="isClient" />
                                            </Grid>
                                            <Grid item className={checkIsCustomer ? 'thumbChecked' : 'thumbUnchecked'}>
                                                Si
                                            </Grid>
                                        </Grid>
                                    }
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <OfferSelector
                                initialAmount={MAXIMUM_AMOUNT}
                                maximumAmount={MAXIMUM_AMOUNT}
                                minimumAmount={MINIMUM_AMOUNT}
                                step={STEP}
                                isCustomer={checkIsCustomer}
                                simulationInstallments={installments}
                                simulationSelectedInstallment={selectedInstallment}
                                onSelect={handleOfferSelected}
                                showSlider={true}
                                analyticsStep={analyticsStep}
                            />
                            <Grid container direction="row">
                                <OfferTerms offer={offer} />
                            </Grid>

                            <div className="center column flex-align-items-center">
                                {popupVisible && (
                                    <div className="simulationPopupCOntainer">
                                        <div className="simulationPopup">
                                            <button className="simulationPopupButton" onClick={closePopup}>
                                                <img src={close} alt="" width="13" height="auto" />
                                            </button>
                                            <img src={moneyIcon} alt="" />
                                            <p>Para poder brindarte una oferta especial para vos, necesitamos conocer tu identidad.</p>
                                        </div>
                                        <div className="arrow-down"></div>
                                    </div>
                                )}
                                <div className="startButton center">
                                    <MainButton onClick={handleNextClicked} fixedBottomMobile>
                                        Solicitar préstamo
                                    </MainButton>
                                </div>
                            </div>

                            <Grid item xs={12}>
                                <p className="fs-14 color-black mt-1 lh-20">
                                    (*) El monto de las cuotas es estimativo, pudiendo variar entre días laborables y fines de semana o feriados. El
                                    IVA ya se encuentra incluido dentro del valor estimado. La cuota explicitada equivale a la cuota promedio a lo
                                    largo de todo el préstamo, por lo cual la primera cuota será mayor y la última cuota será menor al valor mostrado.{' '}
                                </p>
                                <p className="fs-10 color-black mt-1 lh-20 mb-180-res center">
                                    BANCO MACRO S.A. CUIT 30-50001008-4-AV. EDUARDO MADERO N°1182, CABA
                                </p>
                            </Grid>
                            <Grid item>
                                <ShowToaster open={open} textToShow={message} type="error" handleToasterClose={handleToasterClose} />
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </MacroStepPage>
        </>
    );
};
