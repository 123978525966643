import axios from 'axios';
import CryptoJS from 'crypto-js';
import { encode } from 'urlsafe-base64';
import qs from 'qs';
import eventTrackingService from '../services/eventtracking.service';
import { ENCRYPT_KEY, API_URL, ENCRYPT_MESSAGES, getEnv } from '../env';
import { createBrowserHistory } from 'history';

// In this file we can create common headers and add interceptors

const axiosInstance = axios.create({
    baseURL: `${getEnv(API_URL)}`,
    timeout: 120000,
    withCredentials: true,
});

const decrypt = (response) => {
    let encryptedBase64 = response.data;

    if (encryptedBase64 < 10000) {
        return response.data;
    }

    let encryptedBase64Key = getEnv(ENCRYPT_KEY);
    let parsedBase64Key = CryptoJS.enc.Base64.parse(encryptedBase64Key);

    let decrypted = CryptoJS.AES.decrypt(encryptedBase64, parsedBase64Key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
    });

    let parsedJSON = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
    return parsedJSON;
};

axiosInstance.interceptors.request.use(function (request) {
    if (getEnv(ENCRYPT_MESSAGES) === 'true') {
        let encryptedBase64Key = getEnv(ENCRYPT_KEY);
        let parsedBase64Key = CryptoJS.enc.Base64.parse(encryptedBase64Key);

        if (request.params) {
            let param = CryptoJS.AES.encrypt(qs.stringify(request.params), parsedBase64Key, {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7,
            }).toString();

            request.params = param;
        }

        if (request.data && JSON.stringify(request.data) !== '{}') {
            let param = CryptoJS.AES.encrypt(JSON.stringify(request.data), parsedBase64Key, {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7,
            }).toString();

            let encodedParam = encode(param);
            request.data = encodedParam;
        }
    }

    const token = localStorage.getItem('token');
    if (
        request.url !== '/v1/workflow/userinput/combodata' &&
        request.url !== '/v1/faqs/' &&
        request.url !== '/v1/workflow/installments-simulation' &&
        request.url !== '/v1/user/terms/macro-direct-lending' &&
        request.url !== '/v1/workflow/preCustomer/userInputVerification/sendcode' &&
        ///\/v1\/toc\/loanRequest\/([a-z0-9])*\/session-id/g.test(request.url) !== true &&
        request.url !== '/v1/user/terms/accept' &&
        request.url !== '/v1/workflow/start' &&
        !request.url.includes('workflow/minhash') &&
        !request.url.includes('identity-verification') &&
        !request.url.includes('session-id') &&
        !request.url.includes('workflow/workingDay')
    ) {
        request.headers.authorization = token;
    } else {
    }
    return request;
});

axiosInstance.interceptors.response.use(
    (response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger


        if (getEnv(ENCRYPT_MESSAGES) === 'true') {
            console.log(response.config.method);
            console.log(response);
            if (!response.config.url.includes('workflow/download/')
                && (response.config.method === 'post' || !response.config.url.includes('fourIdValidation'))) {
                response.data = decrypt(response);
            }
        }

        let header = response.headers;
        if (header.authorization) {
            localStorage.setItem('token', header.authorization);
        }


        return response;
    },
    (error) => {
        // log error event to analytics
        const errorCode = error.response?.data?.errorCode ? error.response.data.errorCode : error.message ? error.message : error;
        const errorStatus = error.response?.status ? error.response.status : error.status;
        eventTrackingService.trackError(error.config.url, errorStatus, errorCode);

        if (getEnv(ENCRYPT_MESSAGES) === 'true') {
            error.response.data = decrypt(error.response);
        }
        if (errorStatus === 401 || errorStatus === 403) {
            localStorage.removeItem('token');
            const history = createBrowserHistory();
            history.push({ pathname: `/sessionExpired`, search: history.location.search });
            window.location.reload();
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
