import React from 'react';
import { Helmet } from 'react-helmet';
import UseLogin from './components/login/useLogin';
import { Login } from './pages/LoginPage';
import { IdentityVerification } from './pages/IdentityVerificationPage';
import { OfferSelection } from './pages/OfferSelectionPage';
import { InitSelfie4idPage } from './pages/initSelfie4idPage';
import { DocumentsUploadSelfie } from './pages/documentsUploadSelfie';
import { PersonalAddressDataInput } from './pages/PersonalAddressDataInputPage';
import { OfferSimulationPage } from './pages/OfferSimulationPage';
import DisclosurePage from './pages/DisclosurePage';
import { Rejected } from './pages/rejected/RejectedPage';
import { SessionExpired } from './pages/sessionExpiredPage';
import { IdentityValidation } from './pages/IdentityValidationPage';
import ValidateEmailPage from './pages/validateEmailPage';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ErrorPage } from './pages/ErrorPage';
import CustomerLoanRequestSuccessPage from './pages/CustomerLoanRequestSuccessPage';
import { IdVerificationEvaluationPage } from './pages/IdVerificationEvaluationPage';
import RedirectFromMinihash from './components/deviceCommunication/redirectFromMinihash';
import { ProvinceSelection } from './pages/ProvinceSelectionPage';
import { CustomerAfipData } from './pages/CustomerAfipDataPage';
import eventTrackingService from './services/eventtracking.service';
import { OnBoardingNotAvailable } from './pages/OnBoardingNotAvailable';
import { SelectOfficeForAccountOpening } from './pages/SelectOfficeForAccountOpeningPage';
import { AffidavitDataInput } from './pages/AffidavitDataInputPage';
import { DocumentsUploadFront } from './pages/documentsUploadFrontPage';
import { DocumentsUploadBack } from './pages/documentsUploadBackPage';
import { RestartManualDocumentsUpload } from './pages/RestartManualDocumentsUpload';
import AppPasswordCreationPage from './pages/AppPasswordCreationPage';
import WelcomeCallPage from './pages/WelcomeCallPage';
import Faq from './components/faq/faq';
import { API_URL, SALESFORCE_BEACON_SCRIPT_URL, HOTJAR_ENABLED, getEnv } from './env';

eventTrackingService.initialize();

const RESTAPI_URL = `${getEnv(API_URL)}`;
const RESTAPI_VERSION = 'v1';

export default function App() {
    return (
        <Router>
            <Switch>
                <Route
                    path="/identityVerification/:hashKey"
                    render={(props) => <IdentityVerification {...props} taskDefinitionKey="identityVerification" />}
                />{' '}
                <Route path="/offerSelection/:hashKey" render={(props) => <OfferSelection {...props} taskDefinitionKey="offerSelection" />} />{' '}
                <Route
                    path="/documentsUploadSelfieB/:hashKey/"
                    render={(props) => <InitSelfie4idPage {...props} taskDefinitionKey="documentsUploadSelfieB" />}
                />{' '}
                <Route
                    path="/documentsUploadSelfie/:hashKey"
                    render={(props) => <DocumentsUploadSelfie {...props} taskDefinitionKey="documentsUploadSelfie" />}
                />{' '}
                <Route
                    path="/documentsUploadFront/:hashKey"
                    render={(props) => <DocumentsUploadFront {...props} taskDefinitionKey="documentsUploadFront" />}
                />{' '}
                <Route
                    path="/documentsUploadBack/:hashKey"
                    render={(props) => <DocumentsUploadBack {...props} taskDefinitionKey="documentsUploadBack" />}
                />{' '}
                <Route
                    path="/restartManualDocumentsUpload/:hashKey"
                    render={(props) => <RestartManualDocumentsUpload {...props} taskDefinitionKey="restartManualDocumentsUpload" />}
                />{' '}
                <Route
                    path="/restartManualDocumentsUpload/"
                    render={(props) => <RestartManualDocumentsUpload {...props} taskDefinitionKey="restartManualDocumentsUpload" />}
                />{' '}
                <Route path="/u/:minHash/orig/:orig" render={(props) => <RedirectFromMinihash {...props} />} />{' '}
                <Route path="/u/:minHash" render={(props) => <RedirectFromMinihash {...props} />} />{' '}
                <Route
                    path="/st/:minHash"
                    component={(props) => {
                        window.location.href = `${RESTAPI_URL}${RESTAPI_VERSION}/documents/terms/${props.match.params.minHash}`;
                        return null;
                    }}
                />
                <Route
                    path="/identityValidation/:hashKey"
                    render={(props) => <IdentityValidation {...props} taskDefinitionKey="identityValidation" />}
                />{' '}
                <Route
                    path="/personalAddressDataInput/:hashKey"
                    render={(props) => <PersonalAddressDataInput {...props} taskDefinitionKey="personalAddressDataInput" />}
                />{' '}
                <Route
                    path="/affidavitDataInput/:hashKey"
                    render={(props) => <AffidavitDataInput {...props} taskDefinitionKey="affidavitDataInput" />}
                />{' '}
                <Route
                    path="/selectOfficeForAccountOpening/:hashKey"
                    render={(props) => <SelectOfficeForAccountOpening {...props} taskDefinitionKey="selectOfficeForAccountOpening" />}
                />
                <Route path="/validateEmail/:hashKey" render={(props) => <ValidateEmailPage {...props} taskDefinitionKey="validateEmail" />} />{' '}
                <Route path="/disclosure/:hashKey" render={(props) => <DisclosurePage {...props} taskDefinitionKey="disclosure" />} />{' '}
                <Route path="/rejected/:rejectedReasonCode" render={(props) => <Rejected {...props} />} />
                <Route path="/error" render={(props) => <ErrorPage {...props} />} />
                <Route path="/sessionExpired" render={(props) => <SessionExpired {...props} />} />
                <Route path="/faqs" render={(props) => <Faq full={true} {...props} />} />
                <Route path="/faq/:hashKey/:taskDefinitionKey" render={(props) => <Faq full={true} {...props} />} />
                <Route path="/loanActivation/:hashKey" render={(props) => <CustomerLoanRequestSuccessPage {...props} />} />
                <Route path="/welcomeCall/:hashKey" render={(props) => <WelcomeCallPage {...props} />} />
                <Route path="/customerIdentification" render={(props) => <Home {...props} />} />
                <Route path="/onBoardingNotAvailable/:fromHour/:toHour" render={(props) => <OnBoardingNotAvailable {...props} />} />
                <Route path="/idVerificationInboxEvaluation/:hashKey" render={(props) => <IdVerificationEvaluationPage {...props} />} />
                <Route
                    path="/provinceSelection/:hashKey"
                    render={(props) => <ProvinceSelection {...props} taskDefinitionKey="provinceSelection" />}
                />{' '}
                <Route
                    path="/completeCustomerAfipData/:hashKey"
                    render={(props) => <CustomerAfipData {...props} taskDefinitionKey="completeCustomerAfipData" />}
                />{' '}
                <Route
                    path="/appPasswordCreation/:hashKey"
                    render={(props) => <AppPasswordCreationPage {...props} taskDefinitionKey="appPasswordCreation" />}
                />{' '}
                <Route path="/sessionExpired" render={(props) => <SessionExpired {...props} />} />{' '}
                <Route path="/" render={(props) => <OfferSimulationPage {...props} />} />{' '}
                <Route path="/faq" render={(props) => <ErrorPage {...props} />} />{' '}
                <Route path="/manualAutorization/:hashKey" render={(props) => <CustomerLoanRequestSuccessPage {...props} />} />{' '}
            </Switch>{' '}
        </Router>
    );
}

function Home(props) {
    return (
        <UseLogin>
            <Login {...props} />
            <Helmet>{getEnv(HOTJAR_ENABLED) && <script type="text/javascript" src="/hotjar.js" />}</Helmet>
            <Helmet>{getEnv(SALESFORCE_BEACON_SCRIPT_URL) && <script type="text/javascript" src={getEnv(SALESFORCE_BEACON_SCRIPT_URL)} />}</Helmet>
        </UseLogin>
    );
}
