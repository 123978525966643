import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import CurrencyInput from '../customInputs/CurrencyInput';
import { Grid, Box } from '@material-ui/core';
import eventTrackingService from '../../services/eventtracking.service';

const CustomSlider = withStyles({
    root: {
        color: '#07a3db',
        height: 8,
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#07a3db',
        border: '2px solid currentColor',
        marginTop: -8,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 6,
        borderRadius: 4,
    },
    rail: {
        height: 6,
        borderRadius: 4,
        backgroundColor: '#e1e1e1',
    },
})(Slider);

const AmountSelectionSlider = (props) => {
    const [amount, setAmount] = useState(props.value);
    const { maxAmount, minAmount, step } = props;
    const { onAmountChange, showSlider } = props;
    const [value, setValue] = React.useState(amount);

    const [sliderValue, setSliderValue] = React.useState(amount);

    useEffect(() => {
        onAmountChange(amount);
    }, [amount]);

    useEffect(() => {
        if (amount) {
            handleAmountInputChange(amount);
        }
    }, []);

    const handleSliderChangeCommited = (event, newValue) => {
        const direction = amount < newValue ? 'up' : 'down';
        eventTrackingService.trackEvent('simulation-changed', `slider ${direction}`, newValue, 1);
        setAmount(newValue);
    };

    const handleAmountInputChange = (value) => {
        if (value >= step) {
            let newAmount = ~~(value / step) * step;
            setAmount(newAmount);
            setValue(newAmount);
        } else {
            const direction = amount < step ? 'up' : 'down';
            eventTrackingService.trackEvent('simulation-changed', `slider ${direction}`, step, 1);
            setAmount(step);
            setValue(step);
        }
    };

    const handleSliderChange = (event, newValue) => {
        handleChangeValue(event, newValue);
        setSliderValue(newValue);
    };

    const handleChangeValue = (event, newValue) => {
        setValue(newValue);
    };

    const sideMount = (
        <Grid item sm={12} xs={12}>
            <div className="flex center ml-2 mb-1">
                <p style={{ color: '#00a1da', fontSize: '26px', fontWeight: '400', margin:'5px' }}>$</p>
                <CurrencyInput
                    roundInterval={step}
                    defaultValue={amount}
                    value={value}
                    sliderValue={sliderValue}
                    onChange={handleChangeValue}
                    onChangeCommitted={handleAmountInputChange}
                    inputProps={{
                        min: minAmount,
                        max: maxAmount,
                    }}
                />
            </div>
        </Grid>
    );

    const customSlider = (
        <Grid item sm={11} xs={11}>
            <div className="">
                <CustomSlider
                    value={typeof value === 'number' ? value : 0}
                    onChange={handleSliderChange}
                    aria-labelledby="input-slider"
                    max={maxAmount}
                    step={step}
                    defaultValue={amount}
                    onChangeCommitted={handleSliderChangeCommited}
                    min={minAmount}
                />
            </div>
        </Grid>
    );

    const sliderAndMountPosition = (
        <>
            {sideMount} {showSlider && customSlider}
        </>
    );

    return (
        <>
            <Grid container item xs={12} md={10} xl={8}>
                <Grid item container xs={12} direction="row" className="verticalCenterWeb">
                    <Box className="rounderedStepper">
                        <p className="p">2</p>
                    </Box>
                    <Grid item xs={11}>
                        <p id="input-slider" className="fs-16 fw-400 color-primary ml-1">
                            Elegí el monto de tu Préstamo
                        </p>
                        {props.extraInfo && <p className="fs-14 fw-300 color-primary ml-1 mt-1">{props.extraInfo}</p>}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <div className="mt-1"></div>
                    <Grid container direction="row" justifyContent="center">
                        {sliderAndMountPosition}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};
export default AmountSelectionSlider;
