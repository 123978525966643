import React, { useEffect, useState } from 'react';
import useLoanRequest from '../hooks/useLoanRequest';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Grid, Box, Hidden } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import MacroStepPage from './shared/MacroStepPage';
import Toaster from '../components/alerts/Toaster';
import excl from '../assets/exclamacion.svg';
import useCurrentTask from '../components/bpm/useCurrentTask';
import { OfferSelector } from '../components/offerSelection/OfferSelector';
import Loader from '../components/loader/Loader';
import { OfferTerms } from '../components/offerSelection/OfferTerms';
import MainButton from '../components/MainButton';
import { numberToCurrencyText } from '../components/utils/numberUtils';
import { calculateAge } from '../components/utils/dateUtils';
import ComboUserInput from '../components/userInputs/ComboUserInput';
import offerService from '../services/offer.service';
import loanRequestService from '../services/loanRequest.service';
import eventTrackingService from '../services/eventtracking.service';

import '../styles/components/offerSelection.scss';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import ContinueMobile from '../components/deviceCommunication/continueMobile';
import installmentIcon from '../assets/installment-icon.svg';

const MINIMUM_SELECTABLE_AMOUNT = 5000;
const STEP = 1000;
const analyticsStep = 9;

export const OfferSelection = (props) => {
    const { hashKey } = props.match.params;
    const [maxAmountAvailable, setMaxAmountAvailable] = useState(0);
    const [initialAmount, setInitialAmount] = useState(0);
    const [selectedAmount, setSelectedAmount] = useState(0);
    const [installmentPlan, setInstallmentPlan] = useState(null);
    const [message, setMessage] = useState(null);
    const [installments, setInstallments] = useState(null);
    const [loading, setLoading] = useState(null);
    const task = useCurrentTask(hashKey, props.taskDefinitionKey);
    const { loanRequest, loadingLoanRequest } = useLoanRequest(hashKey);
    const [initialProvince, setInitialProvince] = useState(null);
    const [hasAccount, setHasAccount] = useState(false);
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        async function getAvailableAmount() {
            try {
                const maxAvailableAmount = (await offerService.getAvailableAmount(hashKey)).data;
                setMaxAmountAvailable(maxAvailableAmount.value);
                if (loanRequest && loanRequest.productValue) {
                    setInitialAmount(loanRequest.productValue);
                } else {
                    setInitialAmount(maxAvailableAmount.value);
                }
            } catch (error) {
                //
            }
        }
        if (loanRequest && initialProvince) {
            getAvailableAmount();
        }
    }, [loanRequest, initialProvince]);

    useEffect(() => {
        async function setMaxAmountLine() {
            try {
                await offerService.setMaxAmountLine(loanRequest.hashKey);
            } catch (error) {
                //
            }
        }
        async function hasAnyAccount(hashKey) {
            try {
                let hasAnyAccount = (await loanRequestService.hasAnyAccount(hashKey)).data;
                setHasAccount(hasAnyAccount);
            } catch (error) {
                //
            }
        }
        if (loanRequest) {
            setMaxAmountAvailable(loanRequest.maxAmount);
            setMaxAmountLine();
            hasAnyAccount(loanRequest.hashKey);
            if (loanRequest.branchProvinceCode && !initialProvince) {
                setInitialProvince(loanRequest.branchProvinceCode);
            }
        }
    }, [loanRequest]);

    const handleInstallmentPlanSelected = (amount, installmentPlan) => {
        setSelectedAmount(amount);
        setInstallmentPlan(installmentPlan);
    };

    async function saveProvince(province) {
        try {
            setLoading(true);
            await loanRequestService.setProvinceCode(hashKey, province);
            setInitialProvince(province);
            if (selectedAmount) {
                let selectedProductOptions = {
                    selectedProductId: 'CASH_AMOUNT',
                    installmentSelected: {
                        requestedAmount: selectedAmount,
                        availableProducts: [],
                    },
                };
                await loanRequestService.setSelectedProducts(loanRequest.hashKey, selectedProductOptions);
                let newInstallments = (await offerService.getInstallments(loanRequest.hashKey, selectedAmount)).data;
                setInstallments(newInstallments);
                handleInstallmentPlanSelected(selectedAmount, newInstallments[0]);
            }
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }

    const handleNextClicked = (event) => {
        async function saveInstallmentPlan() {
            try {
                setLoading(true);
                eventTrackingService.trackEventWithData(
                    'prestamos_oferta_disponible',
                    {
                        edad: calculateAge(loanRequest.birthDate),
                        monto: loanRequest.maxAmount || loanRequest.response.maxAmount,
                        cuotas: installmentPlan.installments,
                        tasa: installmentPlan.nominalAnnualRate || loanRequest.tna,
                    },
                    null,
                    analyticsStep
                );
                var dto = {
                    installmentAmountSelected: installmentPlan.averageFee,
                    installmentsNumberSelected: installmentPlan.installments,
                    idFinanceLine: installmentPlan.idLinea,
                };
                await offerService.setInstallment(hashKey, dto);
                setLoading(false);
                task.completeTask();
            } catch (error) {
                console.error(error);
                setLoading(false);
                if (error.response) {
                    const { errorCode } = error.response.data;
                    setMessage(t(`WORKFLOW_ERROR.${errorCode}.DESCRIPTION`));
                    setOpen(true);
                } else {
                    console.error(error);
                }
            }
        }
        event.preventDefault();
        saveInstallmentPlan();
    };

    const handleValueChange = (userInput, provinceValue) => {
        saveProvince(provinceValue);
    };

    const ShowToaster = (props) => {
        return <Toaster elevation={6} variant="filled" {...props} />;
    };

    const handleToasterClose = () => {
        setOpen(false);
    };
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const logo = matches ? <img className="logoAdm" src={excl} alt="" /> : null;

    const provinceSelector = loanRequest ? (
        <FormControl style={{ width: '300px' }}>
            <ComboUserInput
                conditioningValue={hashKey}
                hashKey={hashKey}
                stepName={props.taskDefinitionKey}
                userInput={{ name: 'PtllaProvincia', userInputOptions: { label: 'Provincia', defaultValue: loanRequest.branchProvinceCode } }}
                onChange={handleValueChange}
                defaultDisabled={hasAccount}
            />
        </FormControl>
    ) : null;

    return (
        <>
            <Loader loading={loading || loadingLoanRequest} />
            {loanRequest && (
                <MacroStepPage
                    headerLead="¡Tenemos una oferta para vos!"
                    headerTitle={`Tenés disponible un Préstamo hasta ${numberToCurrencyText(maxAmountAvailable)}`}
                    hashKey={hashKey}
                    task={task}
                    taskDefinitionKey={props.taskDefinitionKey}
                    hideStepper={true}
                    stepNumber={2}
                    totalSteps={4}>
                    {!isMobile ? (
                        <ContinueMobile hashKey={hashKey} />
                    ) : (
                        <>
                            <Grid container>
                                <div className="CustomSizeOffer ">
                                    <Grid container direction="column" justifyContent="center" className="">
                                        <Box className="mt-3"></Box>
                                        <Grid item container direction="row" className="mb-1">
                                            <Grid item container xs={12} lg={8} direction="row" className="mt-4 verticalCenterWeb">
                                                <Box className="rounderedStepper">
                                                    <p className="p">1</p>
                                                </Box>
                                                <Grid item xs={10} className="">
                                                    <p className="fs-16 fw-400 color-primary ml-1">
                                                        {' '}
                                                        Elegí la provincia donde se radicará tu cuenta{' '}
                                                    </p>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item container xs={12} direction="row" style={{ marginTop: '10px' }}>
                                            {provinceSelector}
                                        </Grid>
                                        <Grid container>
                                            <Box className="ml-2"></Box>
                                        </Grid>
                                        <Grid item xs={12} className="">
                                            {loanRequest && initialProvince && (
                                                <OfferSelector
                                                    loanRequest={loanRequest}
                                                    initialAmount={initialAmount}
                                                    maximumAmount={maxAmountAvailable}
                                                    minimumAmount={MINIMUM_SELECTABLE_AMOUNT}
                                                    step={STEP}
                                                    simulationInstallments={installments}
                                                    onSelect={handleInstallmentPlanSelected}
                                                    showSlider={false}
                                                    analyticsStep={analyticsStep}
                                                />
                                            )}
                                        </Grid>
                                        <Grid container direction="row">
                                            <OfferTerms offer={installmentPlan} />
                                        </Grid>

                                        <div className="importantDiv flex center mt-1 mb-2">
                                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                                <Grid item container lg={12} sm={12} md={12} xl={12} xs={12}>
                                                    <Grid item container lg={1} sm={3} md={3} xl={1} xs={3} justifyContent="center">
                                                        <img src={installmentIcon} alt="" width="40" height="auto" />
                                                    </Grid>
                                                    <Grid item container lg={11} sm={9} md={9} xl={11} xs={9} style={{ padding: '5px 15px 5px 0' }}>
                                                        <p className="fw-400 fs-14 lh-20 color-dark-gray">
                                                            Las cuotas se debitarán automáticamente de tu cuenta.
                                                        </p>
                                                        {!hasAccount && (
                                                            <p className="fw-400 fs-14 lh-20 color-dark-gray">
                                                                Si no tenés cuenta, ¡no te preocupes! A continuación te solicitaremos los datos para
                                                                abrir una cuenta gratuita.
                                                            </p>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                                {!hasAccount && (
                                                    <Hidden xsDown>
                                                        <Grid item lg={1} sm={1} md={1} xl={1}></Grid>
                                                    </Hidden>
                                                )}
                                            </Grid>
                                        </div>
                                        <Grid className="" container direction="row" justifyContent="center" alignItems="center">
                                            <div className="startButton center">
                                                <MainButton onClick={handleNextClicked} disabled={!installmentPlan} fixedBottomMobile>
                                                    Siguiente
                                                </MainButton>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <p className="fs-14 color-black mt-1 lh-20 mb-180-res">
                                                (*) El monto de las cuotas es estimativo, pudiendo variar entre días laborables y fines de semana o
                                                feriados. El IVA ya se encuentra incluido dentro del valor estimado. La cuota explicitada equivale a
                                                la cuota promedio a lo largo de todo el préstamo, por lo cual la primera cuota será mayor y la última
                                                cuota será menor al valor mostrado.{' '}
                                            </p>
                                        </Grid>
                                        <Grid item>
                                            <ShowToaster open={open} textToShow={message} type="error" handleToasterClose={handleToasterClose} />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </>
                    )}
                </MacroStepPage>
            )}
        </>
    );
};
