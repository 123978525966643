import React, { useState } from 'react';
import { Button, DialogTitle, Dialog, DialogContent, Typography, DialogActions } from '@material-ui/core';

const HelperPopup = ({ helperText, dialogTitle, children, dialogAction, ...props }) => {
    const [openPopup, setOpenPopup] = useState(false);

    const handleClickOpenPopup = () => {
        setOpenPopup(true);
    };

    const handleClosePopup = () => {
        setOpenPopup(false);
    };

    return (
        <>
            <div className="helperTextPopupBox">
                <Button variant="text" onClick={handleClickOpenPopup} className="helpTextPopup" id="helpTextPopupId">
                    {helperText}
                </Button>
            </div>
            <Dialog onClose={handleClosePopup} aria-labelledby="help-dialog-title" open={openPopup}>
                <DialogTitle id="help-dialog-title" onClose={handleClosePopup}>
                    {dialogTitle}
                </DialogTitle>
                <DialogContent>{children}</DialogContent>
                <DialogActions style={{ padding: '0 20px 20px' }}>
                    <Button variant="contained" onClick={handleClosePopup} color="primary" style={{ width: '100%' }}>
                        {dialogAction}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default HelperPopup;
